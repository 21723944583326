import { CircularProgress } from "@mui/joy";

export default function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70%",
      }}
    >
      <CircularProgress determinate={false} size="lg" value={11} variant="plain" />
    </div>
  );
}
