export const helper = {
  convertPublicDeleted: (
    publc: number,
    deleted: number
  ): { type: "deleted" | "public" | "nonpublic"; name: string } => {
    var status = {
      type: "nonpublic" as "deleted" | "public" | "nonpublic",
      name: "Niepubliczny",
    };

    if (deleted === 1) {
      status.type = "deleted";
      status.name = "Usunięty";
    } else if (publc === 1) {
      status.type = "public";
      status.name = "Publiczny";
    } else {
      status.type = "nonpublic";
      status.name = "Niepubliczny";
    }
    return status;
  },

  saveLang: () => {},
};
