import { useParams } from "react-router-dom";
import Card from "@mui/joy/Card";
import { Input } from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import TextEditor from "../../components/TextEditor";
import Grid from "@mui/joy/Grid";
import Switch from "@mui/joy/Switch";
import WarningIcon from "@mui/icons-material/Warning";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import { useState } from "react";
import { useEffect } from "react";
import axiosClient from "../../axiosClient";
import MainCOntainer from "../../components/MainContainer";
import { Coupon } from "../../models/Coupon";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { CouponCategory } from "../../models/CouponCategory";
import { DateTime } from "luxon";
import ImageSection from "../../components/ImageComponents/ImageSection";
import { AppContext } from "../../AppContext";
import { useContext } from "react";

type CouponEditFields = {
  title?: string;
  badge?: string;
  points?: number;
  limit_per_user?: number;
  time_valid_after_buy?: number;
  can_has_multiple?: number;
  description?: string;
  expiration_date?: string;
  couponcategory_id?: number | null;
};
export default function EditCoupon() {
  const { id } = useParams();
  const { setSnackbar } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);
  const [Coupon, setCoupon] = useState<Coupon>();
  const [couponUserCount, setCouponUserCount] = useState(0);
  const [updatedFields, setUpdatedFields] = useState<CouponEditFields>({});
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [couponCategories, setCouponCategories] = useState<Array<CouponCategory>>([]);

  const [description, setDescription] = useState<string | null>(null);

  const handleFieldChange = (field: keyof CouponEditFields, value: any) => {
    const newValues = {
      ...updatedFields,
      [field]: value,
    };

    setUpdatedFields(newValues);

    if (Object.keys(newValues).length === 0) return;

    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    const timeout = setTimeout(() => {
      if (Coupon) {
        axiosClient
          .put(`/admin/coupons/${Coupon.id}`, newValues)
          .then(() => {
            setSnackbar({ title: "Zapisano zmiany.", autoHideDuration: 1000 });
          })
          .catch(() => {
            setSnackbar({ title: "Wystąpił błąd podczas edytowania kuponu.", color: "danger" });
          });
        setUpdatedFields({}); // Wyczyść zmiany po zapisaniu
      }
    }, 1000);

    setTimeoutId(timeout);
  };

  useEffect(() => {
    Promise.all([
      axiosClient.get("/admin/coupons/" + id),
      axiosClient.get("/admin/coupons/" + id + "/couponusers/count"),
      axiosClient.get("/couponcategories"),
    ])
      .then((response) => {
        setCoupon(response[0].data.data);
        setCouponUserCount(response[1].data.data);
        setCouponCategories(response[2].data.data);
      })
      .catch(() => {
        setSnackbar({
          title: "Wystąpił błąd podczas ładowania kuponu.",
          color: "danger",
          autoHideDuration: null,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <MainCOntainer isLoading={isLoading || !Coupon?.id}>
      {couponUserCount > 0 && (
        <Card variant="soft" color="danger">
          <Stack direction="row" justifyContent="left" alignItems="center" spacing={2}>
            <WarningIcon />
            <Typography>
              Ten kupon został już zakupiony przez <b>{couponUserCount}</b> klientów, dlatego nie
              możesz już edytować niektórych pól. Jeśli chcesz zmienić ten kupon, możesz go ukryć
              oraz skorzystać z opcji kopiowania kuponu w celu stworzenia nowego.
            </Typography>
          </Stack>
        </Card>
      )}

      <Grid container spacing={1}>
        <Grid md={7}>
          <Card>
            <FormControl>
              <FormLabel>Tytuł kuponu</FormLabel>
              <Input
                variant="soft"
                defaultValue={Coupon?.title.value}
                disabled={couponUserCount > 0}
                onChange={(value) => {
                  handleFieldChange("title", value.target.value);
                }}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid sm={6}>
                <FormControl>
                  <FormLabel>Odznaka/info np. "-50%"</FormLabel>
                  <Input
                    variant="soft"
                    disabled={couponUserCount > 0}
                    defaultValue={Coupon?.badge.value}
                    onChange={(value) => {
                      handleFieldChange("badge", value.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid sm={6}>
                <FormControl>
                  <FormLabel>Ilość punktów</FormLabel>
                  <Input
                    type="number"
                    variant="soft"
                    defaultValue={Coupon?.points}
                    onChange={(value) => {
                      handleFieldChange("points", value.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <div>
              <FormLabel>Opis kuponu</FormLabel>
              <TextEditor
                value={description ?? Coupon?.description.value ?? ""}
                onChange={(value) => {
                  setDescription(value);
                  handleFieldChange("description", value);
                }}
              />
            </div>
          </Card>
        </Grid>
        <Grid md={5} sm={12}>
          <Card>
            <FormControl>
              <FormLabel>Do kiedy jest ważny kupon? (do kiedy można go kupić)</FormLabel>
              <Input
                type="datetime-local"
                variant="soft"
                defaultValue={
                  Coupon?.expiration_date
                    ? DateTime.fromISO(Coupon?.expiration_date).toFormat("yyyy-MM-dd'T'HH:mm")
                    : ""
                }
                onChange={(event) => {
                  handleFieldChange(
                    "expiration_date",
                    DateTime.fromISO(event.target.value).toFormat("yyyy-MM-dd HH:mm:ss")
                  );
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                Ile dni kupon jest ważny po zakupie? (0 oznacza, że kupon jest ważny zawsze)
                <i>np. 365 oznacza, że kupon jest ważny rok po zakupie</i>
              </FormLabel>
              <Input
                type="number"
                variant="soft"
                disabled={couponUserCount > 0}
                defaultValue={Coupon?.time_valid_after_buy}
                onChange={(value) => {
                  handleFieldChange("time_valid_after_buy", parseInt(value.target.value));
                }}
              />
            </FormControl>

            <Grid container spacing={2}>
              <Grid sm={6}>
                <FormControl>
                  <FormLabel>Ile razy klient może kupić kupon?</FormLabel>
                  <Input
                    variant="soft"
                    type="number"
                    defaultValue={Coupon?.limit_per_user}
                    onChange={(value) => {
                      handleFieldChange("limit_per_user", parseInt(value.target.value));
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid sm={6}>
                <FormLabel>Klient może mieć wiele kuponów?</FormLabel>
                <div style={{ marginTop: 11, display: "flex", justifyContent: "center", gap: 10 }}>
                  <Typography>Nie</Typography>
                  <Switch
                    variant="soft"
                    size="lg"
                    defaultChecked={Coupon?.can_has_multiple ? true : false}
                    onChange={(value) => {
                      handleFieldChange("can_has_multiple", value.target.checked ? 1 : 0);
                    }}
                  />
                  <Typography>Tak</Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
          <div style={{ marginTop: 10 }}>
            <Card>
              <FormControl>
                <FormLabel>Wybierz do jakiej kategorii należy kupon</FormLabel>
                {Coupon && (
                  <Select
                    defaultValue={
                      Coupon?.category === null ? "default" : String(Coupon?.category?.id)
                    }
                    onChange={(e, nextValue) => {
                      handleFieldChange(
                        "couponcategory_id",
                        nextValue == "default" ? null : parseInt(nextValue ?? "0")
                      );
                    }}
                  >
                    <Option value="default">Wszystko</Option>
                    {couponCategories.map((CouponCategory) => {
                      return (
                        <Option key={CouponCategory.id} value={String(CouponCategory.id)}>
                          {CouponCategory.title.value}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormControl>
            </Card>
          </div>
        </Grid>
      </Grid>
      <Card>
        <ImageSection
          imagesProp={Coupon?.images ?? []}
          imageable_type="Coupon"
          imageable_id={Coupon?.id ?? 0}
        />
      </Card>
    </MainCOntainer>
  );
}
