import MainCOntainer from "../../components/MainContainer";
import React, { useEffect, useState } from "react";
import Table from "@mui/joy/Table";
import axiosClient from "../../axiosClient";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import Input from "@mui/joy/Input";
import { Stack } from "@mui/joy";
import Switch from "@mui/joy/Switch";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { AxiosResponse } from "axios";
import { Tab } from "../../models/Tab";
import { Config } from "../../models/Config";
import ConfigImage from "../../components/ImageComponents/ConfigImage";

interface CheckedState {
  [key: string]: number;
}

export default function Configs() {
  const { config: configs, setSnackbar } = useContext(AppContext);
  const [tabnavigators, setTabnavigators] = useState<Array<Tab>>([]);
  const [checked, setChecked] = useState<CheckedState>({
    APP_transfered_points_are_transferable: configs.APP_transfered_points_are_transferable.value,
    AFILATE_points_are_transferable: configs.AFILATE_points_are_transferable.value,
    notify_after_login_user_doesnt_have_phone:
      configs.notify_after_login_user_doesnt_have_phone.value,
  });
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  type Translations = {
    [key: string]: string;
  };
  const translations: Translations = {
    ClubCardPage_Card_ImageBackGround_IMAGE: "Tło dla karty klienta (jpg, jpeg, png)",
    ClubCardPage_Card_Logo_IMAGE: "Logo na karcie klienta (jpg, jpeg, png)",
    LoginPage_logo_IMAGE: "Logo na stronie logowania (jpg, jpeg, png)",
    TabNavigator_Default_Page: "Domyślna strona po wejściu do aplikacji",
    APP_transfered_points_are_transferable: "Czy przelewane punkty mogę być ponownie przelewane?",
    points_to_add_after_phone_add:
      "Ile punktów dodać, jeśli użytkownik doda numer telefonu? (0 oznacza brak przyznania punktów)",
    how_many_time_before_notify_about_visit:
      "O której godzinie mają przychodzić powiadomienia o nadchodzącej wizycie?",
    AFILATE_referrer_points_to_add:
      "Ile punktów ma otrzymać osoba, które poleciła aplikacje innej osobie?",
    AFILATE_referee_points_to_add:
      "Ile punktów ma otrzymać osoba, która została polecona? (Użyła kodu polecjącego)",
    AFILATE_use_limit:
      "Ile razy można kogoś polecić? Np. limit 5 oznacza, że użytkownik może maksymalnie 5 razy otrzymać punkty za polecenie.",
    AFILATE_limit_type: "Na jaki okres czasu jest limit? ",
    AFILATE_points_are_transferable: "Czy punkty uzyskane z poleceń można przelewać?",
    AFILATE_type:
      "Kiedy mają zostać przyznane punkty, kiedy użytkownik skorzysta z kodu polecającego?",
    company_name: "Główna nazwa Twojej firmy.",
    ios_download_url: "Link do pobrania aplikacji IOS",
    android_download_url: "Link do pobrania aplikacji android",
    how_many_years_points_are_valid:
      "Ile lat ważne są punkty? (0 oznacza, że punkty ważne są bezterminowo)",
    notify_after_login_user_doesnt_have_phone:
      "Czy wysyłać powiadomienie push z prośbą o dodanie numeru telefonu, po zalogowaniu, jeśli użytkownik nie ma dodanego numeru telefonu?",
    enum_ever: "Na zawsze",
    enum_month: "Na miesiąc",
    enum_year: "Na rok",
    enum_register: "Podczas rejestracji konta",
    enum_add_phone: "Podczas dodania numeru telefonu",
    home_page_image_item_IMAGE: "Zdjęcie strony głównej (jpg, jpeg, png)",
  };

  useEffect(() => {
    axiosClient
      .get("/tabnavigators")
      .then((response: AxiosResponse) => {
        setTabnavigators(response.data.data);
      })
      .catch(() => {});
  }, []);

  const extractEnumValues = (enumString: string): string[] => {
    const regex = /^enum_(.*)$/;
    const match = enumString.match(regex);

    if (match && match[1]) {
      return match[1].split("-").map((value) => value.trim());
    } else {
      throw new Error("Invalid enum string format");
    }
  };

  const onEdit = (id: number, value: any, name: string, type: string) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    if (type == "bool") {
      value = value == 1 ? true : false;
    }
    const timeout = setTimeout(() => {
      axiosClient
        .put("/admin/configs/" + id, {
          [type]: value,
        })
        .then(() => {
          setSnackbar({ title: "Zapisano zmiany.", autoHideDuration: 1000 });
        })
        .catch(() => {
          setSnackbar({ title: "Wystąpił błąd podczas edytowania kuponu.", color: "danger" });
        });
    }, 1000);
    setTimeoutId(timeout);
  };

  const fieldType = (
    type: string,
    value: any,
    name: string,
    id: number,
    config: Config
  ): React.ReactNode => {
    if (type === "string") {
      return (
        <Input
          variant="soft"
          defaultValue={value}
          onChange={(event) => {
            onEdit(id, event.target.value, name, type);
          }}
        />
      );
    } else if (type === "int") {
      return (
        <Input
          variant="soft"
          defaultValue={value}
          type="number"
          onChange={(event) => {
            onEdit(id, event.target.value, name, type);
          }}
        />
      );
    } else if (type === "imageable") {
      return (
        <Stack direction={"row"} justifyContent={"center"} gap={2}>
          <ConfigImage image={config.image} type="Config" id={config.id} />
        </Stack>
      );
    } else if (type === "app_page") {
      return (
        <Select
          variant="soft"
          defaultValue={value}
          onChange={(event, value) => {
            onEdit(id, value, name, type);
          }}
        >
          {tabnavigators.map((tab: Tab) => (
            <Option key={tab.page.page} value={tab.page.page}>
              {tab.page.page}
            </Option>
          ))}
        </Select>
      );
    } else if (type === "bool") {
      return (
        <Stack direction={"row"} justifyContent={"center"} gap={2}>
          <Switch
            variant="soft"
            defaultChecked={checked[name] == 1 ? true : false}
            onChange={(event) => {
              checked[name] = checked[name] == 1 ? 0 : 1;
              onEdit(id, checked[name], name, type);
              const newChecked = checked;
              setChecked(newChecked);
            }}
          />
        </Stack>
      );
    } else if (type === "hour") {
      return (
        <Input
          type="time"
          variant="soft"
          defaultValue={value}
          onChange={(event) => {
            onEdit(id, value, name, type);
          }}
        />
      );
    } else if (type.includes("enum_")) {
      const enumValues = extractEnumValues(type);
      return (
        <Select
          defaultValue={value}
          onChange={(event, value) => {
            onEdit(id, value, name, type);
          }}
          variant="soft"
        >
          {enumValues.map((enumValue) => (
            <Option key={enumValue} value={enumValue}>
              {translations["enum_" + enumValue]}
            </Option>
          ))}
        </Select>
      );
    }

    return (
      <Input
        variant="soft"
        value={value}
        onChange={(event) => {
          onEdit(id, event.target.value, name, type);
        }}
      />
    );
  };

  return (
    <MainCOntainer isLoading={false}>
      <Table aria-label="basic table" stickyHeader>
        <tbody>
          {Object.keys(configs).map((key) => {
            const config = configs[key];
            if (config.editable) {
              return (
                <tr key={key}>
                  <td>{translations.hasOwnProperty(key) ? translations[key] : config.name}</td>
                  <td>{fieldType(config.type, config.value, config.name, config.id, config)}</td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
    </MainCOntainer>
  );
}
