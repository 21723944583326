import SortableContainer from "../../components/SortableContainer";
import { useState } from "react";
import MainCOntainer from "../../components/MainContainer";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../../components/Modal";
import axiosClient from "../../axiosClient";
import { AxiosResponse } from "axios";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import { CouponCategory } from "../../models/CouponCategory";
import { useEffect } from "react";

function CouponCategories() {
  const [isLoading, setLoading] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [addModalLoading, setAddModalLoading] = useState(false);
  const [CouponCategories, setCouponCategories] = useState<Array<CouponCategory>>([]);

  const { setSnackbar } = useContext(AppContext);

  useEffect(() => {
    axiosClient
      .get("/couponcategories")
      .then((response: AxiosResponse) => {
        setCouponCategories(response.data.data);
      })
      .catch(() => {
        setSnackbar({
          title: "Wystąpił błąd podczas ładowania kuponów.",
          color: "danger",
          autoHideDuration: null,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onAddCouponCategory = () => {
    setAddModalLoading(true);
    axiosClient
      .post("/admin/couponcategories")
      .then((response: AxiosResponse) => {
        setCouponCategories((prevCouponCategories) => [
          ...prevCouponCategories,
          response.data.data,
        ]);
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas dodawania kuponu.", color: "danger" });
      })
      .finally(() => {
        setAddModal(false);
        setAddModalLoading(false);
      });
  };

  const onDragEnd = (CouponCategories: Array<CouponCategory>) => {
    const couponcategoryIds = CouponCategories.map((CouponCategory) => CouponCategory.id);
    axiosClient
      .put("/admin/couponcategories/reorder", { ids: couponcategoryIds })
      .then()
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas sortowania kuponów.", color: "danger" });
      });
  };

  const onDelete = (id: number) => {
    setCouponCategories((CouponCategories) => CouponCategories.filter((item) => item.id != id));
  };

  return (
    <>
      <MainCOntainer isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="primary"
            startDecorator={<AddIcon />}
            size="sm"
            onClick={() => {
              setAddModal(true);
            }}
          >
            Dodaj nową kategorię
          </Button>
        </Box>

        <SortableContainer
          data={CouponCategories}
          type="couponcategory"
          onDragEnd={onDragEnd}
          onDelete={onDelete}
        />
      </MainCOntainer>
      <CustomModal
        isOpen={addModal}
        onOk={onAddCouponCategory}
        onCancel={() => {
          setAddModal(false);
        }}
        title={"Uwaga"}
        body={"Czy chcesz dodać nową kategorię?"}
        okLabel={"Tak"}
        cancelLabel={"Anuluj"}
        color={"primary"}
        isLoading={addModalLoading}
      />
    </>
  );
}

export default CouponCategories;
