import { Image } from "../../models/Image";
import Button from "@mui/joy/Button";
import { useContext, useState } from "react";
import axiosClient from "../../axiosClient";
import { AxiosResponse } from "axios";
import { AppContext } from "../../AppContext";
import { useRef } from "react";
import Modal from "../Modal";
import { AspectRatio } from "@mui/joy";

export default function ConfigImage({
  image,
  type,
  id,
}: {
  image: null | Image;
  type: string;
  id: number;
}) {
  const { setSnackbar } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [Image, setImage] = useState<null | Image>(image);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [deleting, setDeleting] = useState(false);
  const [modal, setModal] = useState(false);
  const [preview, setPreview] = useState<null | Image>(null);

  const onAddImage = () => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  };
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      setLoading(true);
      axiosClient
        .post("/admin/images/change/" + type + "/" + id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response: AxiosResponse) => {
          const newImage = response.data.data;
          setImage(newImage);
        })
        .catch(() => {
          setSnackbar({
            title: "Wystąpił błąd podczas dodawania zdjęcia.",
            color: "danger",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const onPreviewPress = () => {
    setPreview(Image);
  };
  const onDeletePress = () => {
    setModal(true);
  };
  const deleteImage = () => {
    setDeleting(true);
    if (Image == null) {
      return;
    }
    axiosClient
      .delete("/admin/images/" + Image.id)
      .then(() => {
        setImage(null);
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas usuwania zdjęcia.", color: "danger" });
      })
      .finally(() => {
        setDeleting(false);
        setModal(false);
      });
  };
  return (
    <>
      <Button color="primary" size="sm" onClick={onAddImage} loading={loading} variant="soft">
        {Image !== null ? "Zmień zdjęcie" : "Dodaj zdjęcie"}
      </Button>

      {Image !== null && (
        <Button color="danger" size="sm" onClick={onDeletePress} variant="soft">
          {"Usuń zdjęcie"}
        </Button>
      )}
      {Image !== null && (
        <Button color="primary" size="sm" onClick={onPreviewPress} variant="soft">
          Podgląd
        </Button>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Modal
        isOpen={modal}
        onCancel={() => {
          setModal(false);
        }}
        onOk={deleteImage}
        title="Uwaga"
        body="Czy na pewno chcesz usunąć to zdjęcie?"
        okLabel="Tak"
        cancelLabel="Anuluj"
        isLoading={deleting}
      />
      <Modal
        isOpen={preview !== null}
        onOk={() => {
          setPreview(null);
        }}
        okLabel="Ok"
        color="primary"
        children={
          <AspectRatio sx={{ width: 450 }}>
            <img src={preview !== null ? preview.url : ""} loading="lazy" alt="" />
          </AspectRatio>
        }
      />
    </>
  );
}
