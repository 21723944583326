import React from "react";
import { Navigate } from "react-router-dom";
import { User } from "../models/User";

export default function ProtectedRoute({
  user,
  children,
}: {
  user: User | null;
  children: React.ReactNode;
}) {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
}
