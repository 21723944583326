import SortableContainer from "../../components/SortableContainer";
import { useState } from "react";
import MainCOntainer from "../../components/MainContainer";
import axiosClient from "../../axiosClient";
import { AxiosResponse } from "axios";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import { useEffect } from "react";
import { ConfigClubcard } from "../../models/ConfigClubcard";

function ConfigClubcards() {
  const [isLoading, setLoading] = useState(true);
  const [ConfigClubcards, setConfigClubcards] = useState<Array<ConfigClubcard>>([]);

  const { setSnackbar } = useContext(AppContext);

  useEffect(() => {
    axiosClient
      .get("/admin/configs/sites/clubcard")
      .then((response: AxiosResponse) => {
        setConfigClubcards(response.data.data);
      })
      .catch(() => {
        setSnackbar({
          title: "Wystąpił błąd podczas ładowania konfiguracji.",
          color: "danger",
          autoHideDuration: null,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onDragEnd = (ConfigClubcards: Array<ConfigClubcard>) => {
    const configclubcardIds = ConfigClubcards.map((ConfigClubcard) => ConfigClubcard.id);
    axiosClient
      .put("/admin/configs/sites/clubcard/reorder", { ids: configclubcardIds })
      .then()
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas sortowania elementów.", color: "danger" });
      });
  };

  const onDelete = (id: number) => {
    setConfigClubcards((ConfigClubcards) => ConfigClubcards.filter((item) => item.id != id));
  };

  return (
    <>
      <MainCOntainer isLoading={isLoading}>
        <SortableContainer
          data={ConfigClubcards}
          type="configclubcards"
          onDragEnd={onDragEnd}
          onDelete={onDelete}
        />
      </MainCOntainer>
    </>
  );
}

export default ConfigClubcards;
