import React, { createContext, useState, ReactNode } from "react";
import { VariantProp } from "@mui/joy";
import { ColorPaletteProp } from "@mui/joy";
import { Config } from "./models/Config";
import { Page } from "./models/Page";
import { User } from "./models/User";

interface SnackbarProps {
  title: string;
  autoHideDuration?: number | null;
  variant?: VariantProp;
  color?: ColorPaletteProp;
}

type ConfigMap = {
  [key: string]: Config;
};

interface MyContextType {
  snackbar: false | SnackbarProps;
  setSnackbar: React.Dispatch<React.SetStateAction<false | SnackbarProps>>;
  config: ConfigMap;
  setConfig: React.Dispatch<React.SetStateAction<ConfigMap>>;
  diskUsage: number;
  setDiskUsage: React.Dispatch<React.SetStateAction<number>>;
  pages: Array<Page>;
  setPages: React.Dispatch<React.SetStateAction<Array<Page>>>;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const defaultContextValue: MyContextType = {
  snackbar: false,
  setSnackbar: () => {},
  config: {},
  setConfig: () => {},
  diskUsage: 0,
  setDiskUsage: () => {},
  pages: [],
  setPages: () => {},
  user: null,
  setUser: () => {},
};

// Tworzenie kontekstu z domyślną wartością
const AppContext = createContext<MyContextType>(defaultContextValue);

// Definiowanie typu dla komponentu providera
interface MyProviderProps {
  children: ReactNode;
}

// Tworzenie providera kontekstu
const AppContextProvider: React.FC<MyProviderProps> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<false | SnackbarProps>(false);
  const [config, setConfig] = useState<ConfigMap>({});
  const [diskUsage, setDiskUsage] = useState<number>(0);
  const [pages, setPages] = useState<Array<Page>>([]);
  const [user, setUser] = useState<User | null>(null);

  return (
    <AppContext.Provider
      value={{
        snackbar,
        setSnackbar,
        config,
        setConfig,
        diskUsage,
        setDiskUsage,
        pages,
        setPages,
        user,
        setUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
