import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import Coupons from "./sites/coupons/Coupons";
import DragableGrid from "./components/SortableItem";
import EditCoupon from "./sites/coupons/EditCoupon";
import Snackbar from "@mui/joy/Snackbar";
import { useContext, useState } from "react";
import { AppContext } from "./AppContext";
import CouponCategories from "./sites/couponcategories/CouponCategories";
import Companies from "./sites/companies/Companies";
import axiosClient from "./axiosClient";
import { useEffect } from "react";
import Configs from "./sites/configs/Configs";
import ConfigClubcards from "./sites/configsites/ConfigClubcards";
import ConfigHomes from "./sites/configsites/ConfigHomes";
import Login from "./Login";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "./components/Loader";
import ProtectedRoute from "./components/ProtectedRoute";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

export default function InnerApp() {
  const { snackbar, setSnackbar, setConfig, setDiskUsage, setPages, user, setUser } =
    useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      try {
        const [userResponse, configsValue, disUsageValue, pagesValue] = await Promise.all([
          axiosClient.get("/users/auth"),
          axiosClient.get("/admin/configs"),
          axiosClient.get("/admin/common/disk-usage"),
          axiosClient.get("/admin/pages"),
        ]);
        setUser(userResponse.data.data);
        setConfig(configsValue.data.data);
        setDiskUsage(disUsageValue.data.data);
        setPages(pagesValue.data.data);
      } catch (e) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  if (loading) {
    return (
      <CssVarsProvider disableTransitionOnChange>
        <Loader />
      </CssVarsProvider>
    );
  }

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        {location.pathname !== "/login" && user && <Header />}
        {location.pathname !== "/login" && user && <Sidebar />}
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: "calc(12px + var(--Header-height))",
              sm: "calc(12px + var(--Header-height))",
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            gap: 1,
          }}
        >
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute user={user}>
                  <DragableGrid />
                </ProtectedRoute>
              }
            />
            <Route
              path="/coupons"
              element={
                <ProtectedRoute user={user}>
                  <Coupons />
                </ProtectedRoute>
              }
            />
            <Route
              path="/coupons/:id"
              element={
                <ProtectedRoute user={user}>
                  <EditCoupon />
                </ProtectedRoute>
              }
            />

            <Route
              path="/couponcategories"
              element={
                <ProtectedRoute user={user}>
                  <CouponCategories />
                </ProtectedRoute>
              }
            />
            <Route
              path="/companies"
              element={
                <ProtectedRoute user={user}>
                  <Companies />
                </ProtectedRoute>
              }
            />

            <Route
              path="/configs"
              element={
                <ProtectedRoute user={user}>
                  <Configs />
                </ProtectedRoute>
              }
            />
            <Route
              path="/configclubcards"
              element={
                <ProtectedRoute user={user}>
                  <ConfigClubcards />
                </ProtectedRoute>
              }
            />
            <Route
              path="/confighomes"
              element={
                <ProtectedRoute user={user}>
                  <ConfigHomes />
                </ProtectedRoute>
              }
            />

            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:intent/:email/:code" element={<ResetPassword />} />
          </Routes>
        </Box>
      </Box>
      <Snackbar
        autoHideDuration={
          snackbar !== false ? (snackbar?.autoHideDuration === null ? null : 2000) : null
        }
        variant={snackbar !== false ? snackbar?.variant ?? "soft" : "soft"}
        color={snackbar !== false ? snackbar?.color ?? "neutral" : "neutral"}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbar !== false}
        onClose={() => {
          setSnackbar(false);
        }}
      >
        {snackbar !== false ? snackbar.title : ""}
      </Snackbar>
    </CssVarsProvider>
  );
}
