import Button from "@mui/joy/Button";
import SortableContainer from "../../components/SortableContainer";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import { Image } from "../../models/Image";
import { useState } from "react";
import { useRef } from "react";
import axiosClient from "../../axiosClient";
import { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

export default function ImageSection({
  imagesProp,
  imageable_type,
  imageable_id,
}: {
  imagesProp: Array<Image>;
  imageable_type: string;
  imageable_id: number;
}) {
  const [images, setImages] = useState(imagesProp);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { setSnackbar } = useContext(AppContext);

  const onAddImage = () => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      setLoading(true);
      axiosClient
        .post("/admin/images/" + imageable_type + "/" + imageable_id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response: AxiosResponse) => {
          const newImage = response.data.data;
          setImages((prevImages) => [...prevImages, newImage]);
        })
        .catch(() => {
          setSnackbar({
            title: "Wystąpił błąd podczas dodawania zdjęcia.",
            color: "danger",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const onReorder = (images: Array<Image>) => {
    const imageIds = images.map((image) => image.id);
    axiosClient
      .put("/admin/images/reorder", { ids: imageIds })
      .then(() => {})
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas sortowania zdjęć.", color: "danger" });
      });
  };

  const onDelete = (id: number) => {
    setImages((images) => images.filter((item) => item.id != id));
  };

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography>Zdjęcia przypisane do kuponu</Typography>
        <Button variant="soft" style={{ width: 150 }} onClick={onAddImage} loading={loading}>
          Dodaj zdjęcie
        </Button>
      </Stack>
      <SortableContainer data={images} type="image" onDragEnd={onReorder} onDelete={onDelete} />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </>
  );
}
