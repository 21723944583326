import { Card, Button, AspectRatio, Stack } from "@mui/joy";
import { useState } from "react";
import axiosClient from "../../axiosClient";
import { AxiosResponse } from "axios";
import Box from "@mui/joy/Box";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import CustomModal from "../Modal";
import { Company } from "../../models/Company";
import Input from "@mui/joy/Input";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import { useRef } from "react";

export default function CompanyItem({
  data,
  onDelete,
}: {
  data: Company;
  onDelete?: (id: number) => void;
}) {
  const { setSnackbar } = useContext(AppContext);
  const [Company, setCompany] = useState<Company>(data);
  const [loading, setLoading] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const [deleteImageModalLoading, setDeleteImageModalLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  if (!Company?.id) {
    return <></>;
  }
  const handleUpdate = (Company: Company) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    const timeout = setTimeout(() => {
      console.log(Company);

      axiosClient
        .put("/admin/companies/" + Company.id, {
          name: Company.name,
          city: Company.city,
          zip_code: Company.zip_code,
          street: Company.street,
          lat: Company.lat,
          lon: Company.lon,
        })
        .then(() => {
          setSnackbar({ title: "Zapisano zmiany.", autoHideDuration: 1000 });
        })
        .catch(() => {
          setSnackbar({ title: "Wystąpił błąd podczas edytowania lokalizacji.", color: "danger" });
        });
    }, 1000);
    setTimeoutId(timeout);
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      setLoading(true);
      axiosClient
        .post("/admin/images/change/Company/" + Company.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response: AxiosResponse) => {
          const newImage = response.data.data;
          Company.image = newImage;
          setCompany(Company);
        })
        .catch(() => {
          setSnackbar({
            title: "Wystąpił błąd podczas zmiany zdjęcia.",
            color: "danger",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const deleteImage = () => {
    if (!Company.image) {
      return;
    }
    setDeleteImageModalLoading(true);

    axiosClient
      .delete("/admin/images/" + Company.image.id)
      .then(() => {
        Company.image = null;
        setCompany(Company);
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas usuwania zdjęcia.", color: "danger" });
      })
      .finally(() => {
        setDeleteImageModalLoading(false);
        setDeleteImageModal(false);
      });
  };

  const deleteCompany = () => {
    axiosClient
      .delete("/admin/companies/" + Company.id)
      .then(() => {
        if (!onDelete) {
          return;
        }
        onDelete(Company.id);
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas dodawania lokalizacji.", color: "danger" });
      })
      .finally(() => {
        setDeleteModal(false);
        setDeleteModalLoading(false);
      });
  };
  const onAddImage = () => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  };

  return (
    <>
      <Card variant="outlined" sx={{ width: 400 }}>
        <AspectRatio minHeight="120px" maxHeight="200px">
          {Company.image && <img src={Company.image.url} loading="lazy" alt="" />}
        </AspectRatio>
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Nazwa</FormLabel>
            <Input
              variant="soft"
              value={Company.name}
              onChange={(value) => {
                const updated = { ...Company } as Company;
                updated.name = value.target.value;
                setCompany(updated as Company);
                handleUpdate(updated as Company);
              }}
            />
          </FormControl>
          <Stack direction={"row"} spacing={2}>
            <Stack minWidth={0}>
              <FormControl>
                <FormLabel>Miasto</FormLabel>
                <Input
                  variant="soft"
                  value={Company.city}
                  onChange={(value) => {
                    const updated = { ...Company } as Company;
                    updated.city = value.target.value;
                    setCompany(updated as Company);
                    handleUpdate(updated as Company);
                  }}
                />
              </FormControl>
            </Stack>

            <Stack minWidth={0}>
              <FormControl>
                <FormLabel>Kod pocztowy</FormLabel>
                <Input
                  variant="soft"
                  value={Company.zip_code}
                  onChange={(value) => {
                    const updated = { ...Company } as Company;
                    updated.zip_code = value.target.value;
                    setCompany(updated as Company);
                    handleUpdate(updated as Company);
                  }}
                />
              </FormControl>
            </Stack>
          </Stack>
          <FormControl>
            <FormLabel>Ulica</FormLabel>
            <Input
              variant="soft"
              value={Company.street}
              onChange={(value) => {
                const updated = { ...Company } as Company;
                updated.street = value.target.value;
                setCompany(updated as Company);
                handleUpdate(updated as Company);
              }}
            />
          </FormControl>
          <Stack direction={"row"} spacing={2}>
            <Stack minWidth={0}>
              <FormControl>
                <FormLabel>Szerokość geogr.</FormLabel>
                <Input
                  type="text"
                  variant="soft"
                  value={Company.lat}
                  onChange={(value) => {
                    const updated = { ...Company } as Company;
                    updated.lat = value.target.value.replace(",", ".");
                    setCompany(updated as Company);
                    handleUpdate(updated as Company);
                  }}
                />
              </FormControl>
            </Stack>

            <Stack minWidth={0}>
              <FormControl>
                <FormLabel>Długość geogr.</FormLabel>
                <Input
                  type="text"
                  variant="soft"
                  value={Company.lon}
                  onChange={(value) => {
                    const updated = { ...Company } as Company;
                    updated.lon = value.target.value.replace(",", ".");
                    setCompany(updated as Company);
                    handleUpdate(updated as Company);
                  }}
                />
              </FormControl>
            </Stack>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction={"row"} gap={1}>
              <Button
                variant="soft"
                size="md"
                color="primary"
                aria-label="Zmień zdjęcie"
                sx={{ fontWeight: 600 }}
                onClick={onAddImage}
                loading={loading}
              >
                Zmień zdjęcie
              </Button>
              <Button
                variant="soft"
                size="md"
                color="danger"
                aria-label="Usuń zdjęcie"
                sx={{ fontWeight: 600 }}
                onClick={() => {
                  setDeleteImageModal(true);
                }}
                disabled={Company.image === null}
              >
                Usuń zdjęcie
              </Button>
              <Button
                variant="soft"
                size="md"
                color="danger"
                aria-label="Edytuj"
                sx={{ fontWeight: 600 }}
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                Usuń
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Card>
      <CustomModal
        isOpen={deleteModal}
        onOk={deleteCompany}
        onCancel={() => {
          setDeleteModal(false);
        }}
        title={"Uwaga"}
        body={"Czy chcesz usunąć tą lokalizację?"}
        okLabel={"Tak"}
        cancelLabel={"Anuluj"}
        color={"danger"}
        isLoading={deleteModalLoading}
      />
      <CustomModal
        isOpen={deleteImageModal}
        onOk={deleteImage}
        onCancel={() => {
          setDeleteImageModal(false);
        }}
        title={"Uwaga"}
        body={"Czy chcesz usunąć zdjęcie?"}
        okLabel={"Tak"}
        cancelLabel={"Anuluj"}
        color={"danger"}
        isLoading={deleteImageModalLoading}
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </>
  );
}
