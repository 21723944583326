import { useState, useCallback } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { Stack } from "@mui/joy";
import { useEffect } from "react";
import SortableItem from "./SortableItem";

const SortableContainer = ({
  data,
  type,
  onDragEnd,
  onDelete,
  triggerRefresh,
}: {
  data: any;
  type: "coupon" | "image" | "couponcategory" | "configclubcards" | "confighomes";
  onDragEnd: (data: any) => any;
  onDelete?: (id: number) => any;
  triggerRefresh?: () => void;
}) => {
  const [items, setItems] = useState<Array<any>>(data);

  const [activeId, setActiveId] = useState<string | null | any>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleDragEnd = useCallback((e: DragEndEvent) => {
    const { active, over } = e;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIdx = items.findIndex((item) => item.id.toString() === e.active.id.toString());
        const newIdx = items.findIndex((item) => item.id.toString() === e.over?.id.toString());
        onDragEnd(arrayMove(items, oldIdx, newIdx));
        return arrayMove(items, oldIdx, newIdx);
      });
    }

    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
          {items.map((item) => (
            <SortableItem
              key={item.id}
              id={item.id}
              item={item}
              type={type}
              onDelete={(id: number) => {
                if (onDelete) {
                  onDelete(id);
                }
              }}
              onUpdate={(newItem: any) => {
                setItems((prevItems) =>
                  prevItems.map((item) => (item.id === newItem.id ? { ...item, ...newItem } : item))
                );
              }}
              triggerRefresh={() => {
                if (triggerRefresh) {
                  triggerRefresh();
                }
              }}
            />
          ))}
        </Stack>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
        {activeId ? (
          <SortableItem
            id={activeId}
            isDragging
            item={items.find((c) => c.id === activeId)}
            type={type}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default SortableContainer;
