import Modal from "@mui/material/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Divider from "@mui/joy/Divider";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import Button from "@mui/joy/Button";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { ColorPaletteProp } from "@mui/joy";

export default function CustomModal({
  isOpen,
  onOk,
  onCancel,
  title,
  body,
  okLabel,
  cancelLabel,
  isLoading = false,
  color = "danger",
  children,
}: {
  isOpen: boolean;
  onOk: () => void;
  onCancel?: () => void;
  title?: string;
  body?: string;
  okLabel: string;
  cancelLabel?: string;
  isLoading?: boolean;
  color?: ColorPaletteProp;
  children?: React.ReactNode;
}) {
  return (
    <Modal
      open={isOpen}
      onClose={onCancel}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen} timeout={500}>
        <div>
          <ModalDialog variant="outlined" role="alertdialog">
            <DialogTitle>
              {title && <WarningRoundedIcon />}
              {children && children}
              {!children && title}
            </DialogTitle>
            <Divider />
            <DialogContent>{body}</DialogContent>
            <DialogActions>
              <Button variant="solid" color={color} onClick={onOk} loading={isLoading}>
                {okLabel}
              </Button>
              <Button variant="plain" color="neutral" onClick={onCancel}>
                {cancelLabel}
              </Button>
            </DialogActions>
          </ModalDialog>
        </div>
      </Fade>
    </Modal>
  );
}
