import { Card, AspectRatio } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import OpenWithRoundedIcon from "@mui/icons-material/OpenWithRounded";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { CSSProperties } from "react";
import { Image } from "../../models/Image";
import Modal from "../Modal";
import { useState } from "react";
import axiosClient from "../../axiosClient";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

export default function ImageItem({
  data,
  listeners,
  style,
  onDelete,
}: {
  data: Image;
  listeners: SyntheticListenerMap | undefined;
  style: CSSProperties;
  onDelete: (id: number) => void;
}) {
  const [modal, setModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { setSnackbar } = useContext(AppContext);

  const onDeletePress = () => {
    setModal(true);
  };
  const deleteImage = () => {
    setDeleting(true);

    axiosClient
      .delete("/admin/images/" + data.id)
      .then(() => {
        onDelete(data.id);
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas usuwania zdjęcia.", color: "danger" });
      })
      .finally(() => {
        setDeleting(false);
        setModal(false);
      });
  };

  return (
    <>
      <Card sx={{ width: 350 }}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Button color="danger" variant="soft" onClick={onDeletePress}>
            Usuń
          </Button>
          <div {...listeners} style={style}>
            <OpenWithRoundedIcon color="primary" />
          </div>
        </Stack>
        <AspectRatio minHeight="120px" maxHeight="200px">
          <img src={data.url} loading="lazy" alt="" />
        </AspectRatio>
      </Card>
      <Modal
        isOpen={modal}
        onCancel={() => {
          setModal(false);
        }}
        onOk={() => {
          deleteImage();
        }}
        title="Uwaga"
        body="Czy na pewno chcesz usunąć to zdjęcie?"
        okLabel="Tak"
        cancelLabel="Anuluj"
        isLoading={deleting}
      />
    </>
  );
}
