import { useState } from "react";
import MainCOntainer from "../../components/MainContainer";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../../components/Modal";
import axiosClient from "../../axiosClient";
import { AxiosResponse } from "axios";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import { useEffect } from "react";
import { Stack } from "@mui/joy";
import { Company } from "../../models/Company";
import CompanyItem from "../../components/CompanyComponents/CompanyItem";

function Companies() {
  const [isLoading, setLoading] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [addModalLoading, setAddModalLoading] = useState(false);
  const [Companies, setCompanies] = useState<Array<Company>>([]);

  const { setSnackbar } = useContext(AppContext);

  useEffect(() => {
    axiosClient
      .get("/companies")
      .then((response: AxiosResponse) => {
        setCompanies(response.data.data);
      })
      .catch(() => {
        setSnackbar({
          title: "Wystąpił błąd podczas ładowania lokalizacji.",
          color: "danger",
          autoHideDuration: null,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onAddCompany = () => {
    setAddModalLoading(true);
    axiosClient
      .post("/admin/companies")
      .then((response: AxiosResponse) => {
        setCompanies((prevCompanyCategories) => [...prevCompanyCategories, response.data.data]);
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas dodawania lokalizacji.", color: "danger" });
      })
      .finally(() => {
        setAddModal(false);
        setAddModalLoading(false);
      });
  };

  const onDeleteCompany = (id: number) => {
    setCompanies(
      Companies.filter((Company) => {
        if (id !== Company.id) {
          return Company;
        }
      })
    );
  };
  return (
    <>
      <MainCOntainer isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="primary"
            startDecorator={<AddIcon />}
            size="sm"
            onClick={() => {
              setAddModal(true);
            }}
          >
            Dodaj nową lokalizację
          </Button>
        </Box>
        <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
          {Companies.map((Company) => (
            <CompanyItem data={Company} onDelete={onDeleteCompany} key={Company.id} />
          ))}
        </Stack>
      </MainCOntainer>
      <CustomModal
        isOpen={addModal}
        onOk={onAddCompany}
        onCancel={() => {
          setAddModal(false);
        }}
        title={"Uwaga"}
        body={"Czy chcesz dodać nową lokalizację?"}
        okLabel={"Tak"}
        cancelLabel={"Anuluj"}
        color={"primary"}
        isLoading={addModalLoading}
      />
    </>
  );
}

export default Companies;
