import {
  Card,
  Typography,
  Chip,
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  Option,
} from "@mui/joy";
import Stack from "@mui/joy/Stack";
import OpenWithRoundedIcon from "@mui/icons-material/OpenWithRounded";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { CSSProperties } from "react";
import Switch from "@mui/joy/Switch";
import PublicOffRoundedIcon from "@mui/icons-material/PublicOffRounded";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { helper } from "../../helper";
import { ColorPaletteProp } from "@mui/joy/styles/types";
import { useState } from "react";
import axiosClient from "../../axiosClient";
import { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { ConfigHome } from "../../models/ConfigHome";
import { Page } from "../../models/Page";
import CustomModal from "../../components/Modal";

export default function ConfigHomeItem({
  data,
  listeners,
  style,
  onUpdate,
  onDelete,
  triggerRefresh,
}: {
  data: ConfigHome;
  listeners: SyntheticListenerMap | undefined;
  style: CSSProperties;
  onUpdate: (ConfigHome: ConfigHome) => void;
  onDelete: (id: number) => void;
  triggerRefresh: () => void;
}) {
  const { setSnackbar, pages } = useContext(AppContext);
  type Translations = {
    [key: string]: string;
  };
  const translations: Translations = {
    homeImage: "Zdjęcie na stronie głównej",
    homeButton: "Przycisk przenoszący do dowlonego miejsca w aplikacji lub na strone www",
    homeCoupons: "Lista najnowszych kuponów",
    homeServices: "Lista najnowszych usług",
  };
  const options: any = {
    homeButton: { copy: true, customRedirect: true, pageRedirect: true, titles: true },
    homeImage: { copy: false, customRedirect: true, pageRedirect: true, titles: false },
    homeCoupons: { copy: false, customRedirect: false, pageRedirect: false, titles: false },
    homeServices: { copy: false, customRedirect: false, pageRedirect: false, titles: false },
  };
  const [ConfigHome, setConfigHome] = useState<ConfigHome>(data);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalLodaing, setDeleteModalLoading] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [copyModalLodaing, setCopyModalLoading] = useState(false);

  const del = () => {
    setDeleteModalLoading(true);
    axiosClient
      .delete("/admin/configs/sites/home/" + ConfigHome.id)
      .then(() => {
        onDelete(ConfigHome.id);
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas usuwania elementu.", color: "danger" });
      })
      .finally(() => {
        setDeleteModal(false);
        setDeleteModalLoading(false);
      });
  };

  const copy = () => {
    setCopyModalLoading(true);
    axiosClient
      .post("/admin/configs/sites/home/" + ConfigHome.id)
      .then((response: AxiosResponse) => {
        triggerRefresh();
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas edycji elementu.", color: "danger" });
      })
      .finally(() => {
        setCopyModal(false);
        setCopyModalLoading(false);
      });
  };

  const updateShow = (show: number) => {
    axiosClient
      .put("/admin/configs/sites/home/" + ConfigHome.id, {
        show: show,
      })
      .then((response: AxiosResponse) => {
        onUpdate(ConfigHome);
        setConfigHome(response.data.data);
        setSnackbar({ title: "Zapisano zmiany.", autoHideDuration: 1000 });
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas edycji elementu.", color: "danger" });
      });
  };

  const update = (ConfigHome: ConfigHome) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    const timeout = setTimeout(() => {
      axiosClient
        .put("/admin/configs/sites/home/" + ConfigHome.id, {
          title: ConfigHome.title?.value,
          second_title: ConfigHome.second_title?.value,
          custom_redirect: ConfigHome.custom_redirect,
          page_id: ConfigHome.page_id,
        })
        .then((response: AxiosResponse) => {
          onUpdate(ConfigHome);
          setConfigHome(response.data.data);
          setSnackbar({ title: "Zapisano zmiany.", autoHideDuration: 1000 });
        })
        .catch(() => {
          setSnackbar({ title: "Wystąpił błąd podczas edycji elementu.", color: "danger" });
        });
    }, 1000);
    setTimeoutId(timeout);
  };

  var status = helper.convertPublicDeleted(ConfigHome.show, 0);
  return (
    <>
      <Card
        sx={{ width: 350, minHeight: 470 }}
        color={
          {
            public: "success",
            nonpublic: "warning",
            deleted: "neutral",
          }[status.type] as ColorPaletteProp
        }
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: 470,
          }}
        >
          <Stack gap={1}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Chip
                variant="soft"
                size="sm"
                startDecorator={
                  {
                    public: <PublicRoundedIcon />,
                    nonpublic: <PublicOffRoundedIcon />,
                    deleted: <DeleteForeverRoundedIcon />,
                  }[status.type]
                }
                color={
                  {
                    public: "success",
                    nonpublic: "warning",
                    deleted: <DeleteForeverRoundedIcon />,
                  }[status.type] as ColorPaletteProp
                }
              >
                {status.name}
              </Chip>
              <div {...listeners} style={style}>
                <OpenWithRoundedIcon color="primary" />
              </div>
            </Stack>
            <Typography>{translations[ConfigHome.component]}</Typography>
            {options[ConfigHome.component].titles && (
              <>
                <FormControl>
                  <FormLabel>Tytuł</FormLabel>
                  <Input
                    variant="soft"
                    value={ConfigHome?.title?.value ?? ""}
                    onChange={(event) => {
                      const updatedConfigHome = { ...ConfigHome } as any;
                      updatedConfigHome.title = { value: event.target.value };

                      setConfigHome(updatedConfigHome);

                      update(updatedConfigHome);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Drugi tytuł</FormLabel>
                  <Input
                    variant="soft"
                    value={ConfigHome?.second_title?.value ?? ""}
                    onChange={(event) => {
                      const updatedConfigHome = { ...ConfigHome } as any;
                      updatedConfigHome.second_title = { value: event.target.value };
                      setConfigHome(updatedConfigHome);
                      update(updatedConfigHome);
                    }}
                  />
                </FormControl>
              </>
            )}
            {options[ConfigHome.component].customRedirect && (
              <FormControl>
                <FormLabel>Link do strony, na która ma przekierować po kliknięciu</FormLabel>
                <Input
                  variant="soft"
                  value={ConfigHome?.custom_redirect ?? ""}
                  onChange={(event) => {
                    const updatedConfigHome = { ...ConfigHome } as any;
                    updatedConfigHome.custom_redirect = event.target.value;
                    setConfigHome(updatedConfigHome);
                    update(updatedConfigHome);
                  }}
                />
              </FormControl>
            )}
            {options[ConfigHome.component].pageRedirect && (
              <FormControl>
                <FormLabel>Strona</FormLabel>
                <Select
                  variant="soft"
                  defaultValue={ConfigHome.page?.id}
                  onChange={(event, value) => {
                    const updatedConfigHome = { ...ConfigHome } as any;
                    updatedConfigHome.page_id = value;
                    console.log(updatedConfigHome);

                    setConfigHome(updatedConfigHome);
                    update(updatedConfigHome);
                  }}
                >
                  <Option key={null} value={""}>
                    Brak
                  </Option>
                  {pages.map(function (page: Page) {
                    if (page.params === null) {
                      return (
                        <Option key={page.id} value={page.id}>
                          {page.page}
                        </Option>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            )}
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction={"row"} gap={1}>
              {options[ConfigHome.component].copy && (
                <Button
                  variant="soft"
                  size="md"
                  color="primary"
                  aria-label="Kopiuj"
                  sx={{ fontWeight: 600, ml: 1 }}
                  onClick={() => {
                    setCopyModal(true);
                  }}
                >
                  Kopiuj
                </Button>
              )}
              {options[ConfigHome.component].copy && ConfigHome.id != 2 && (
                <Button
                  variant="soft"
                  size="md"
                  color="danger"
                  aria-label="Usuń"
                  sx={{ fontWeight: 600 }}
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                >
                  Usuń
                </Button>
              )}
            </Stack>
            <Switch
              variant="soft"
              checked={ConfigHome.show === 1}
              onChange={(event) => {
                const newValue = event.target.checked ? 1 : 0;

                setConfigHome((prevItem) => {
                  return { ...prevItem, show: newValue };
                });

                updateShow(newValue);
              }}
            />
          </Box>
        </Box>
      </Card>
      <CustomModal
        isOpen={deleteModal}
        onOk={del}
        onCancel={() => {
          setDeleteModal(false);
          setDeleteModalLoading(false);
        }}
        title={"Uwaga"}
        body={"Czy chcesz usunąć ten element?"}
        okLabel={"Tak"}
        cancelLabel={"Anuluj"}
        color={"danger"}
        isLoading={deleteModalLodaing}
      />
      <CustomModal
        isOpen={copyModal}
        onOk={copy}
        onCancel={() => {
          setCopyModal(false);
          setCopyModalLoading(false);
        }}
        title={"Uwaga"}
        body={"Czy chcesz skopiować ten element?"}
        okLabel={"Tak"}
        cancelLabel={"Anuluj"}
        color={"primary"}
        isLoading={copyModalLodaing}
      />
    </>
  );
}
