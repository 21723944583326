import axios, { AxiosInstance } from "axios";
import { settings } from "./settings";
import Cookies from "js-cookie";

const axiosClient: AxiosInstance = axios.create({
  baseURL: settings.baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor dla żądań - dodawanie tokenu do nagłówków
axiosClient.interceptors.request.use(
  (config) => {
    const token = Cookies.get(settings.storage.jwt_cookie);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = config.baseURL?.replace(
      "[intent]",
      (process.env.REACT_APP_TENANT_PREFIX ?? "") +
        localStorage.getItem(settings.storage.company_key) || ""
    );
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor dla odpowiedzi - obsługa błędów globalnych
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response, config } = error;
    if (
      response?.status === 401 &&
      ![
        "/users/auth",
        "/admin/configs",
        "/admin/common/disk-usage",
        "/admin/pages",
        "/auth/login/admin",
      ].includes(config.url)
    ) {
      window.location.href = "/login";
    } else if (response?.data?.details?.reason === "disk_space_limit_exceeded") {
      console.warn("Disk space limit exceeded");
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
