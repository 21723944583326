import { CssVarsProvider } from "@mui/joy/styles";
import { Button } from "@mui/joy";
import Card from "@mui/joy/Card";
import Stack from "@mui/joy/Stack";
import { settings } from "./settings";
import { Input, Typography } from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import axiosClient from "./axiosClient";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import { Navigate } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import zxcvbn from "zxcvbn";
import validator from "validator";
import { Turnstile } from "@marsidev/react-turnstile";
import React from "react";
import type { TurnstileInstance } from "@marsidev/react-turnstile";

const getColorForScore = (score: number): "danger" | "warning" | "success" => {
  switch (score) {
    case 0:
      return "danger";
    case 1:
      return "danger";
    case 2:
      return "warning";
    case 3:
      return "success";
    case 4:
      return "success";
  }
  return "danger";
};

export default function ResetPassword() {
  const { user } = useContext(AppContext);
  const { intent, email, code } = useParams();
  const ref = React.useRef<TurnstileInstance | null>(null);
  const [newPassword, setNewPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [passwdScore, setPasswdScore] = useState<null | number>(null);

  const [passwordError, setPasswordError] = useState<null | string>(null);
  const [passwordRepeatError, setPasswordRepeatError] = useState<null | string>(null);

  const [responseError, setResponseError] = useState<null | string>(null);

  const [cf_token, set_cf_token] = useState<string | null>(null);

  useEffect(() => {
    const score = zxcvbn(newPassword).score;
    setPasswdScore(score);
    if (score >= 0 && newPassword) {
      switch (score) {
        case 0:
          return setPasswordError("Siła hasła: bardzo słabe");
        case 1:
          return setPasswordError("Siła hasła: słabe");
        case 2:
          return setPasswordError("Siła hasła: dobre");
        case 3:
          return setPasswordError("Siła hasła: bardzo dobre");
        case 4:
          return setPasswordError("Siła hasła: wspaniałe");
      }
    } else {
      setPasswordError(null);
    }
  }, [newPassword]);

  const onResetPassword = () => {
    if (isLoading) {
      return false;
    }

    let errors = 0;
    if (validator.isEmpty(newPassword)) {
      setPasswordError("To pole nie może być puste.");
      errors++;
    } else {
      if (passwdScore !== null && passwdScore <= 2) {
        setPasswordError("Twoje hasło jest za słabe.");
        errors++;
      } else {
        setPasswordError(null);
      }
    }

    if (validator.isEmpty(passwordRepeat)) {
      setPasswordRepeatError("To pole nie może być puste.");
      errors++;
    } else {
      if (newPassword !== passwordRepeat) {
        setPasswordRepeatError("Podane hasła nie zgadzają się.");
        errors++;
      } else {
        setPasswordRepeatError(null);
      }
    }

    if (errors > 0) {
      return;
    }
    ref.current?.getResponsePromise().then(() => {
      setLoading(true);
      axiosClient
        .post(
          "/auth/reset-passwd/dashboard",
          {
            email: email,
            token: code,
            newPassword: newPassword,
            repeatNewPassword: passwordRepeat,
          },
          {
            headers: {
              "X-CF-Turnstile-Token": cf_token,
            },
          }
        )
        .then(() => {
          navigate("/login");
        })
        .catch((error) => {
          ref.current?.reset();
          set_cf_token(null);
          const { response } = error;
          if (response.data.details.reason === "token_code_expired") {
            return setResponseError("Link resetujący hasło wygasł.");
          }
          if (response.data.details.reason === "token_code_mismatch") {
            return setResponseError("Link resetujący hasło jest nieprawidłowy.");
          }

          return setResponseError("Wystąpił nieoczekiwany błąd.");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  if (user) {
    return <Navigate to={"/"} replace />;
  }
  return (
    <CssVarsProvider disableTransitionOnChange>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Stack sx={{ marginBottom: 2 }}>
          <img style={{ height: 180, width: 180 }} src={settings.selfImage("Logo.png")} alt="" />
        </Stack>
        <Card size="lg" sx={{ width: "90%", maxWidth: 400 }}>
          <FormControl>
            <FormLabel>Wprowadź nowe hasło*</FormLabel>
            <Input
              variant="soft"
              onChange={(value) => {
                setNewPassword(value.target.value);
              }}
              type="password"
            />
            {passwordError !== null && (
              <Typography color={getColorForScore(passwdScore ?? 0)} fontSize={"small"}>
                {passwordError}
              </Typography>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Wprowadź ponownie hasło*</FormLabel>
            <Input
              variant="soft"
              onChange={(value) => {
                setPasswordRepeat(value.target.value);
              }}
              type="password"
            />
            {passwordRepeatError !== null && (
              <Typography color="danger" fontSize={"small"}>
                {passwordRepeatError}
              </Typography>
            )}
          </FormControl>
          {responseError !== null && (
            <Typography color="danger" fontSize={"small"}>
              {responseError}
            </Typography>
          )}
          <Button
            color="primary"
            size="sm"
            onClick={onResetPassword}
            loading={isLoading}
            disabled={isLoading || cf_token === null}
          >
            Zmień hasło
          </Button>
          <Turnstile
            ref={ref}
            siteKey={process.env.REACT_APP_CF_SITE_KEY ?? "x"}
            onSuccess={set_cf_token}
          />
        </Card>
      </Stack>
    </CssVarsProvider>
  );
}
