import SortableContainer from "../../components/SortableContainer";
import { useState } from "react";
import MainCOntainer from "../../components/MainContainer";
import axiosClient from "../../axiosClient";
import { AxiosResponse } from "axios";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import { useEffect } from "react";
import { ConfigHome } from "../../models/ConfigHome";

function ConfigHomes() {
  const [isLoading, setLoading] = useState(true);
  const [ConfigHomes, setConfigHomes] = useState<Array<ConfigHome>>([]);
  const [refresh, triggerRefresh] = useState(false);
  const { setSnackbar } = useContext(AppContext);

  useEffect(() => {
    axiosClient
      .get("/admin/configs/sites/home")
      .then((response: AxiosResponse) => {
        setConfigHomes(response.data.data);
      })
      .catch(() => {
        setSnackbar({
          title: "Wystąpił błąd podczas ładowania konfiguracji.",
          color: "danger",
          autoHideDuration: null,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh]);

  const onDragEnd = (ConfigHomes: Array<ConfigHome>) => {
    const configclubcardIds = ConfigHomes.map((ConfigClubcard) => ConfigClubcard.id);
    axiosClient
      .put("/admin/configs/sites/home/reorder", { ids: configclubcardIds })
      .then()
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas sortowania elementów.", color: "danger" });
      });
  };

  const onDelete = (id: number) => {
    setConfigHomes((ConfigHomes) => ConfigHomes.filter((item) => item.id != id));
  };

  return (
    <>
      <MainCOntainer isLoading={isLoading}>
        <SortableContainer
          data={ConfigHomes}
          type="confighomes"
          onDragEnd={onDragEnd}
          onDelete={onDelete}
          triggerRefresh={() => {
            triggerRefresh(!refresh);
          }}
        />
      </MainCOntainer>
    </>
  );
}

export default ConfigHomes;
