import { FC } from "react";
import { CSSProperties } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import CouponItem from "./CouponComponents/CouponItem";
import ImageItem from "./ImageComponents/ImageItem";
import CouponCategoryItem from "./CouponCategoryComponents/CouponCategoryItem";
import ConfigClubcardItem from "./ConfigSitesComponents/ConfigClubcardItem";
import ConfigHomeItem from "./ConfigSitesComponents/ConfigHomeItem";

const SortableItem: FC<any> = (props) => {
  const { isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const inlineStyles: CSSProperties = {
    borderRadius: "10px",
    cursor: isDragging ? "grabbing" : "grab",
  };
  const styles: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    opacity: isDragging ? "0.5" : "1",
  };
  const data = props?.item as any;

  if (!data) {
    return <></>;
  }

  if (props.type === "coupon") {
    return (
      <div ref={setNodeRef} style={styles}>
        <CouponItem
          listeners={listeners}
          style={inlineStyles}
          data={data}
          onDelete={(id) => {
            if (!props.onDelete) {
              return;
            }
            props.onDelete(id);
          }}
        />
      </div>
    );
  } else if (props.type === "image") {
    return (
      <div ref={setNodeRef} style={styles}>
        <ImageItem
          listeners={listeners}
          style={inlineStyles}
          data={data}
          onDelete={(id) => {
            if (!props.onDelete) {
              return;
            }
            props.onDelete(id);
          }}
        />
      </div>
    );
  } else if (props.type === "couponcategory") {
    return (
      <div ref={setNodeRef} style={styles}>
        <CouponCategoryItem
          listeners={listeners}
          style={inlineStyles}
          data={data}
          onDelete={(id) => {
            if (!props.onDelete) {
              return;
            }
            props.onDelete(id);
          }}
        />
      </div>
    );
  } else if (props.type === "configclubcards") {
    return (
      <div ref={setNodeRef} style={styles}>
        <ConfigClubcardItem
          listeners={listeners}
          style={inlineStyles}
          data={data}
          onUpdate={(ConfigClubcard) => {
            if (props.onUpdate) {
              props.onUpdate(ConfigClubcard);
            }
          }}
        />
      </div>
    );
  } else if (props.type === "confighomes") {
    return (
      <div ref={setNodeRef} style={styles}>
        <ConfigHomeItem
          listeners={listeners}
          style={inlineStyles}
          data={data}
          onUpdate={(ConfigClubcard) => {
            if (props.onUpdate) {
              props.onUpdate(ConfigClubcard);
            }
          }}
          onDelete={(id) => {
            if (!props.onDelete) {
              return;
            }
            props.onDelete(id);
          }}
          triggerRefresh={() => {
            if (props.triggerRefresh) {
              props.triggerRefresh();
            }
          }}
        />
      </div>
    );
  } else {
    return <></>;
  }
};
export default SortableItem;
