import { Card, Typography, Chip } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import OpenWithRoundedIcon from "@mui/icons-material/OpenWithRounded";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { CSSProperties } from "react";
import { ConfigClubcard } from "../../models/ConfigClubcard";
import Switch from "@mui/joy/Switch";
import PublicOffRoundedIcon from "@mui/icons-material/PublicOffRounded";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { helper } from "../../helper";
import { ColorPaletteProp } from "@mui/joy/styles/types";
import { useState } from "react";
import axiosClient from "../../axiosClient";
import { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

export default function ConfigClubcardItem({
  data,
  listeners,
  style,
  onUpdate,
}: {
  data: ConfigClubcard;
  listeners: SyntheticListenerMap | undefined;
  style: CSSProperties;
  onUpdate: (ConfigClubcard: ConfigClubcard) => void;
}) {
  const { setSnackbar } = useContext(AppContext);
  type Translations = {
    [key: string]: string;
  };
  const translations: Translations = {
    points: "Element zawierający informacje o ilości punktów",
    transferPoints: "Przycisk przenoszący do przelewania punktów",
    userCoupons: "Trzy najnowsze zakupione kupony użytkownika",
    userServices: "Trzy najnowsze zakupione usługi użytkownika",
  };
  const [ConfigClubcard, setConfigClubcard] = useState<ConfigClubcard>(data);

  const update = (ConfigClubcard: ConfigClubcard) => {
    onUpdate(ConfigClubcard);
    axiosClient
      .put("/admin/configs/sites/clubcard/" + ConfigClubcard.id, { show: ConfigClubcard.show })
      .then((response: AxiosResponse) => {})
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas edycji elementu.", color: "danger" });
      });
  };

  var status = helper.convertPublicDeleted(ConfigClubcard.show, 0);
  return (
    <>
      <Card
        sx={{ width: 350 }}
        color={
          {
            public: "success",
            nonpublic: "warning",
            deleted: "neutral",
          }[status.type] as ColorPaletteProp
        }
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Chip
            variant="soft"
            size="sm"
            startDecorator={
              {
                public: <PublicRoundedIcon />,
                nonpublic: <PublicOffRoundedIcon />,
                deleted: <DeleteForeverRoundedIcon />,
              }[status.type]
            }
            color={
              {
                public: "success",
                nonpublic: "warning",
                deleted: <DeleteForeverRoundedIcon />,
              }[status.type] as ColorPaletteProp
            }
          >
            {status.name}
          </Chip>
          <div {...listeners} style={style}>
            <OpenWithRoundedIcon color="primary" />
          </div>
        </Stack>
        <Typography>{translations[ConfigClubcard.component]}</Typography>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Switch
            variant="soft"
            checked={ConfigClubcard.show === 1}
            onChange={(event) => {
              const newValue = event.target.checked ? 1 : 0;

              setConfigClubcard((prevItem) => {
                return { ...prevItem, show: newValue };
              });

              ConfigClubcard.show = newValue;
              update(ConfigClubcard);
            }}
          />
        </Stack>
      </Card>
    </>
  );
}
