import { Card, Chip, Button, Typography, AspectRatio, Stack } from "@mui/joy";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import PublicOffRoundedIcon from "@mui/icons-material/PublicOffRounded";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import { CSSProperties, useState } from "react";
import { Coupon } from "../../models/Coupon";
import { ColorPaletteProp } from "@mui/joy/styles/types";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import OpenWithRoundedIcon from "@mui/icons-material/OpenWithRounded";
import moment from "moment";
import { helper } from "../../helper";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../axiosClient";
import Box from "@mui/joy/Box";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import CustomModal from "../Modal";

export default function CouponItem({
  listeners,
  style,
  data,
  onDelete,
}: {
  listeners: SyntheticListenerMap | undefined;
  style: CSSProperties;
  data?: Coupon;
  onDelete?: (id: number) => void;
}) {
  const navigate = useNavigate();
  const { setSnackbar } = useContext(AppContext);
  const [Coupon, setCoupon] = useState<Coupon | undefined>(data);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);

  if (!Coupon?.id) {
    return <></>;
  }
  var status = helper.convertPublicDeleted(Coupon.public, Coupon.deleted);

  const onClickEdit = () => {
    navigate("/coupons/" + Coupon.id);
  };

  const onClickVisibility = () => {
    axiosClient
      .put("/admin/coupons/" + Coupon.id, { public: Coupon.public ? 0 : 1 })
      .then(() => {
        setCoupon((prevCoupon) => {
          if (!prevCoupon) return undefined;
          return { ...prevCoupon, public: prevCoupon.public ? 0 : 1 };
        });
      })
      .catch(() => {
        setSnackbar({
          title: "Wystąpił błąd podczas ustawiania widoczności kuponu.",
          color: "danger",
        });
      });
  };

  const deleteCoupon = () => {
    setDeleteModalLoading(true);
    axiosClient
      .delete("/admin/coupons/" + Coupon.id)
      .then(() => {
        if (onDelete) {
          onDelete(Coupon.id);
        }
      })
      .catch(() => {
        setSnackbar({
          title: "Wystąpił błąd podczas usuwania kuponu.",
          color: "danger",
        });
      })
      .finally(() => {
        setDeleteModalLoading(false);
        setDeleteModal(false);
      });
  };

  return (
    <>
      <Card
        sx={{ width: 350 }}
        color={
          {
            public: "success",
            nonpublic: "warning",
            deleted: "neutral",
          }[status.type] as ColorPaletteProp
        }
        variant="outlined"
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Chip
              variant="soft"
              size="sm"
              startDecorator={
                {
                  public: <PublicRoundedIcon />,
                  nonpublic: <PublicOffRoundedIcon />,
                  deleted: <DeleteForeverRoundedIcon />,
                }[status.type]
              }
              color={
                {
                  public: "success",
                  nonpublic: "warning",
                  deleted: "neutral",
                }[status.type] as ColorPaletteProp
              }
            >
              {status.name}
            </Chip>
            <div {...listeners} style={style}>
              <OpenWithRoundedIcon color="primary" />
            </div>
          </div>
          <Typography level="title-lg">{Coupon.title.value}</Typography>
          <Typography level="body-sm">
            Ważny do: {moment(Coupon.expiration_date).format("DD/MM/Y - HH:mm")}
          </Typography>
        </div>
        <AspectRatio minHeight="120px" maxHeight="200px">
          {Coupon.images[0]?.name && <img src={Coupon.images[0].url} loading="lazy" alt="" />}
        </AspectRatio>
        <Stack spacing={2}>
          <Typography fontSize="lg" fontWeight="lg">
            {Coupon.badge.value}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction={"row"} gap={1}>
              <Button
                variant="soft"
                size="md"
                color="primary"
                aria-label="Edytuj"
                sx={{ fontWeight: 600, ml: 1 }}
                onClick={onClickEdit}
              >
                Edytuj
              </Button>
              <Button
                variant="soft"
                size="md"
                color="danger"
                aria-label="Edytuj"
                sx={{ fontWeight: 600 }}
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                Usuń
              </Button>
            </Stack>
            <Button
              variant="outlined"
              size="md"
              color="primary"
              sx={{ fontWeight: 600 }}
              onClick={onClickVisibility}
            >
              {Coupon.public === 1 ? "Ukryj" : "Odkryj"}
            </Button>
          </Box>
        </Stack>
      </Card>
      <CustomModal
        isOpen={deleteModal}
        onOk={deleteCoupon}
        onCancel={() => {
          setDeleteModal(false);
        }}
        title={"Uwaga"}
        body={"Czy chcesz usunąć ten kupon?"}
        okLabel={"Tak"}
        cancelLabel={"Anuluj"}
        color={"danger"}
        isLoading={deleteModalLoading}
      />
    </>
  );
}
