import { CssVarsProvider } from "@mui/joy/styles";
import { Button } from "@mui/joy";
import Card from "@mui/joy/Card";
import Stack from "@mui/joy/Stack";
import { settings } from "./settings";
import { Input } from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import axiosClient from "./axiosClient";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import { Navigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { Typography } from "@mui/joy";
import validator from "validator";
import { Turnstile } from "@marsidev/react-turnstile";
import React from "react";
import type { TurnstileInstance } from "@marsidev/react-turnstile";

export default function ForgotPassword() {
  const { user } = useContext(AppContext);
  const ref = React.useRef<TurnstileInstance | null>(null);
  const [company, setCompany] = useState(localStorage.getItem(settings.storage.company_key));
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [companyError, setCompanyError] = useState(false);

  const [responseError, setResponseError] = useState("");

  const [cf_token, set_cf_token] = useState<string | null>(null);
  useEffect(() => {}, []);

  const onSendUrl = () => {
    if (isLoading) {
      return;
    }

    let errors = 0;
    if (!validator.isEmail(email)) {
      setEmailError(true);
      errors++;
    } else {
      setEmailError(false);
    }
    if (validator.isEmpty(company ?? "")) {
      setCompanyError(true);
      errors++;
    } else {
      setCompanyError(false);
    }

    if (errors > 0) {
      return;
    }
    ref.current?.getResponsePromise().then(() => {
      setIsLoading(true);
      axiosClient
        .post(
          "/auth/reset-passwd-token/dashboard",
          { email: email },
          {
            headers: {
              "X-CF-Turnstile-Token": cf_token,
            },
          }
        )
        .then(() => {
          setSent(true);
        })
        .catch((error) => {
          const { response } = error;
          ref.current?.reset();
          set_cf_token(null);
          if (response.data.details.reason === "try_again_later_at") {
            return setResponseError("Link został już w wysłany. Spróbuj ponownie później.");
          }
          return setResponseError("Wystąpił nieoczekiwany błąd.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };
  if (user) {
    return <Navigate to={"/"} replace />;
  }

  if (sent) {
    return (
      <CssVarsProvider disableTransitionOnChange>
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <Stack sx={{ marginBottom: 2 }}>
            <img style={{ height: 180, width: 180 }} src={settings.selfImage("Logo.png")} alt="" />
          </Stack>
          <Card
            size="lg"
            sx={{
              display: "flex",
              width: "90%",
              maxWidth: 400,
              alignItems: "center",
            }}
          >
            <CheckIcon sx={{ fontSize: 100 }} />
            <Typography fontSize={30} sx={{ textAlign: "center" }}>
              Wysłano link z resetem hasła!
            </Typography>
          </Card>
        </Stack>
      </CssVarsProvider>
    );
  }
  return (
    <CssVarsProvider disableTransitionOnChange>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Stack sx={{ marginBottom: 2 }}>
          <img style={{ height: 180, width: 180 }} src={settings.selfImage("Logo.png")} alt="" />
        </Stack>
        <Card size="lg" sx={{ width: "90%", maxWidth: 400 }}>
          <FormControl>
            <FormLabel>Identyfikator firmy*</FormLabel>
            <Input
              variant="soft"
              defaultValue={company ?? ""}
              onChange={(value) => {
                setCompany(value.target.value);
                localStorage.setItem(settings.storage.company_key, value.target.value);
              }}
              type="text"
            />
            {companyError && (
              <Typography color="danger" fontSize={"small"}>
                To pole nie może być puste.
              </Typography>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Adres e-mail*</FormLabel>
            <Input
              variant="soft"
              onChange={(value) => {
                setEmail(value.target.value);
              }}
              type="email"
            />
            {emailError && (
              <Typography color="danger" fontSize={"small"}>
                Adres e-mail jest nieprawidłowy.
              </Typography>
            )}
          </FormControl>
          {responseError && (
            <Typography color="danger" fontSize={"small"}>
              {responseError}
            </Typography>
          )}
          <Button
            color="primary"
            size="sm"
            onClick={onSendUrl}
            loading={isLoading}
            disabled={isLoading || cf_token === null}
          >
            Wyślij link do resetu hasła
          </Button>
          <Turnstile
            ref={ref}
            siteKey={process.env.REACT_APP_CF_SITE_KEY ?? "x"}
            onSuccess={set_cf_token}
          />
        </Card>
      </Stack>
    </CssVarsProvider>
  );
}
