import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import InnerApp from "./InnerApp";
import "react-quill/dist/quill.snow.css"; // import stylów Quill
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as extendMaterialTheme,
  THEME_ID,
} from "@mui/material/styles";
import { AppContextProvider } from "./AppContext";

export default function App() {
  const materialTheme = extendMaterialTheme();
  return (
    <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
      <AppContextProvider>
        <Router>
          <InnerApp />
        </Router>
      </AppContextProvider>
    </MaterialCssVarsProvider>
  );
}
