import { Card } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import OpenWithRoundedIcon from "@mui/icons-material/OpenWithRounded";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { CSSProperties } from "react";
import Modal from "../Modal";
import { useState } from "react";
import axiosClient from "../../axiosClient";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { CouponCategory } from "../../models/CouponCategory";
import Input from "@mui/joy/Input";

export default function CouponCategoryItem({
  data,
  listeners,
  style,
  onDelete,
}: {
  data: CouponCategory;
  listeners: SyntheticListenerMap | undefined;
  style: CSSProperties;
  onDelete: (id: number) => void;
}) {
  const [modal, setModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { setSnackbar } = useContext(AppContext);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [CouponCategory, setCouponCategory] = useState<CouponCategory>(data);

  const onDeletePress = () => {
    setModal(true);
  };
  const deleteImage = () => {
    setDeleting(true);

    axiosClient
      .delete("/admin/couponcategories/" + data.id)
      .then(() => {
        onDelete(data.id);
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas usuwania kategorii.", color: "danger" });
      })
      .finally(() => {
        setDeleting(false);
        setModal(false);
      });
  };

  const handleUpdate = (CouponCategory: CouponCategory) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    const timeout = setTimeout(() => {
      axiosClient
        .put("/admin/couponcategories/" + CouponCategory.id, {
          title: CouponCategory.title.value,
        })
        .then(() => {
          setSnackbar({ title: "Zapisano zmiany.", autoHideDuration: 1000 });
        })
        .catch(() => {
          setSnackbar({ title: "Wystąpił błąd podczas edytowania kategorii.", color: "danger" });
        });
    }, 1000);
    setTimeoutId(timeout);
  };

  return (
    <>
      <Card sx={{ width: 350 }}>
        <Input
          variant="soft"
          value={CouponCategory.title.value}
          onChange={(value) => {
            const updatedCategory = { ...CouponCategory } as any;
            updatedCategory.title.value = value.target.value;
            setCouponCategory(updatedCategory as CouponCategory);
            handleUpdate(updatedCategory as CouponCategory);
          }}
        />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Button color="danger" variant="soft" onClick={onDeletePress}>
            Usuń
          </Button>
          <div {...listeners} style={style}>
            <OpenWithRoundedIcon color="primary" />
          </div>
        </Stack>
      </Card>
      <Modal
        isOpen={modal}
        onCancel={() => {
          setModal(false);
        }}
        onOk={() => {
          deleteImage();
        }}
        title="Uwaga"
        body="Czy na pewno chcesz usunąć tą kategorię?"
        okLabel="Tak"
        cancelLabel="Anuluj"
        isLoading={deleting}
      />
    </>
  );
}
