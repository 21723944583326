export const settings = {
  baseUrl: process.env.REACT_APP_API_URL,
  selfImage: function (name: string) {
    return process.env.REACT_APP_LOCAL_IMAGES_URL + name;
  },
  storage: {
    company_key: "localstorage_company_key",
    jwt_cookie: "jwt_token_loyalwolfs",
  },
};
