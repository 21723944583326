import { CssVarsProvider } from "@mui/joy/styles";
import { Button } from "@mui/joy";
import Card from "@mui/joy/Card";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { settings } from "./settings";
import { Input } from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import axiosClient from "./axiosClient";
import { useState } from "react";
import Cookies from "js-cookie";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import { AxiosResponse } from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import validator from "validator";

export default function Login() {
  const { setUser, user, setSnackbar } = useContext(AppContext);
  const [company, setCompany] = useState(localStorage.getItem(settings.storage.company_key));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [responseError, setResponseError] = useState("");

  const onLogin = () => {
    if (isLoading) {
      return;
    }

    let errors = 0;
    if (validator.isEmpty(company ?? "")) {
      setCompanyError(true);
      errors++;
    } else {
      setCompanyError(false);
    }
    if (!validator.isEmail(email)) {
      setEmailError(true);
      errors++;
    } else {
      setEmailError(false);
    }
    if (validator.isEmpty(password)) {
      setPasswordError(true);
      errors++;
    } else {
      setPasswordError(false);
    }
    if (errors > 0) {
      return;
    }
    setIsLoading(true);
    axiosClient
      .post("/auth/login/admin", { email: email, password: password, platform: "ios" })
      .then((response: AxiosResponse) => {
        setUser(response.data.data.user);
        Cookies.set(settings.storage.jwt_cookie, response.data.data.token, { expires: 365 });
        window.location.reload();
      })
      .catch((error) => {
        const { response } = error;
        if (response.data.details.reason == "not_found") {
          return setResponseError("Podane nieprawidłowy identyfikator firmy.");
        }
        if (response.data.details.reason == "invalid_credentials") {
          return setResponseError("Podane nieprawidłowy e-mail lub hasło.");
        }
        if (response.data.details.reason == "try_again_later") {
          return setResponseError("Za dużo prób. Spróbuj ponownie później.");
        }
        return setResponseError("Wystąpił nieoczekiwany błąd.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  if (user) {
    return <Navigate to={"/"} replace />;
  }
  return (
    <CssVarsProvider disableTransitionOnChange>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Stack sx={{ marginBottom: 2 }}>
          <img style={{ height: 180, width: 180 }} src={settings.selfImage("Logo.png")} alt="" />
        </Stack>
        <Card size="lg" sx={{ width: "90%", maxWidth: 400 }}>
          <FormControl>
            <FormLabel>Identyfikator firmy*</FormLabel>
            <Input
              variant="soft"
              defaultValue={company ?? ""}
              onChange={(value) => {
                setCompany(value.target.value);
                localStorage.setItem(settings.storage.company_key, value.target.value);
              }}
              type="text"
            />
            {companyError && (
              <Typography color="danger" fontSize={"small"}>
                To pole nie może być puste.
              </Typography>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Adres e-mail*</FormLabel>
            <Input
              variant="soft"
              onChange={(value) => {
                setEmail(value.target.value);
              }}
              type="email"
            />
            {emailError && (
              <Typography color="danger" fontSize={"small"}>
                Niepoprawny adres email.
              </Typography>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Hasło*</FormLabel>
            <Input
              variant="soft"
              onChange={(value) => {
                setPassword(value.target.value);
              }}
              type="password"
            />
            {passwordError && (
              <Typography color="danger" fontSize={"small"}>
                To pole nie może być puste.
              </Typography>
            )}
          </FormControl>
          {responseError && (
            <Typography color="danger" fontSize={"small"}>
              {responseError}
            </Typography>
          )}
          <Typography
            component="a"
            onClick={() => {
              navigate("/forgotpassword");
            }}
            sx={{
              textDecorationLine: "underline",
              alignSelf: "flex-end",
              cursor: "pointer",
            }}
          >
            Nie pamiętam hasła
          </Typography>

          <Button color="primary" size="sm" onClick={onLogin} loading={isLoading}>
            Zaloguj się
          </Button>
        </Card>
      </Stack>
    </CssVarsProvider>
  );
}
