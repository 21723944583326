import SortableContainer from "../../components/SortableContainer";
import { useEffect } from "react";
import axiosClient from "../../axiosClient";
import { AxiosResponse } from "axios";
import { Coupon } from "../../models/Coupon";
import { useState } from "react";
import MainCOntainer from "../../components/MainContainer";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import CustomModal from "../../components/Modal";

const Coupons = () => {
  const [Coupons, setCoupons] = useState<Array<Coupon>>([]);
  const [isLoading, setLoading] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [addModalLoading, setAddModalLoading] = useState(false);

  const { setSnackbar } = useContext(AppContext);

  useEffect(() => {
    axiosClient
      .get("/admin/coupons")
      .then((response: AxiosResponse) => {
        setCoupons(response.data.data);
      })
      .catch(() => {
        setSnackbar({
          title: "Wystąpił błąd podczas ładowania kuponów.",
          color: "danger",
          autoHideDuration: null,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const onDragEnd = (Coupons: Array<Coupon>) => {
    const couponIds = Coupons.map((Coupon) => Coupon.id);
    axiosClient
      .put("/admin/coupons/reorder", { ids: couponIds })
      .then()
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas sortowania kuponów.", color: "danger" });
      });
  };

  const onAddCoupon = () => {
    setAddModalLoading(true);
    axiosClient
      .post("/admin/coupons")
      .then((response: AxiosResponse) => {
        setCoupons((prevCoupons) => [...prevCoupons, response.data.data]);
      })
      .catch(() => {
        setSnackbar({ title: "Wystąpił błąd podczas dodawania kuponu.", color: "danger" });
      })
      .finally(() => {
        setAddModal(false);
        setAddModalLoading(false);
      });
  };
  const onDelete = (id: number) => {
    setCoupons((Coupons) => Coupons.filter((item) => item.id != id));
  };

  return (
    <>
      <MainCOntainer isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="primary"
            startDecorator={<AddIcon />}
            size="sm"
            onClick={() => {
              setAddModal(true);
            }}
          >
            Dodaj nowy kupon
          </Button>
        </Box>

        <SortableContainer data={Coupons} type="coupon" onDragEnd={onDragEnd} onDelete={onDelete} />
      </MainCOntainer>
      <CustomModal
        isOpen={addModal}
        onOk={onAddCoupon}
        onCancel={() => {
          setAddModal(false);
        }}
        title={"Uwaga"}
        body={"Czy chcesz dodać nowy kupon?"}
        okLabel={"Tak"}
        cancelLabel={"Anuluj"}
        color={"primary"}
        isLoading={addModalLoading}
      />
    </>
  );
};

export default Coupons;
